// ======================================
//         Video Placeholder
// ======================================

.video-placeholder {
    background: #EDF1F4;
    padding: 40px 0;
    &--container {
        max-width: 456px;
    }
}
