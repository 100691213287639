// ======================================
//           CTA Alert Block
// ======================================

.cta-alert {
    background: #EDF1F4;
    padding: 40px 0;
    &--container {
        background: rgb(153,40,30);
        background: linear-gradient(104deg, rgba(153,40,30,1) 0%, rgba(204,1,18,1) 100%);
        padding: 56px 62px;
        max-width: 924px;
        color: $white;
        @media screen and (max-width: 768px) {
            padding: 62px 12px;
        }
    }
    &--title {
        color: $white;
        font-size: 38px;
        line-height: 48px;
        font-family: $secondary-bold-font, serif;
    }
    &--desc {
        font-size: 18px;
        line-height: 24px;
        padding: 22px 0;
        font-family: $primary-font, sans-serif;
    }
    &--buttons {
        .btn {
            margin-right: 10px;
            &:hover {
                background: $black;
                border-color: $black;
                color: $white;
            }
        }
    }
}