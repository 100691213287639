// ===============================
//     Headline Block Styles
// ===============================

.headline-block {
    background: #EDF1F4;
    &--breadcrumbs {
        position: absolute;
        top: 0;
        z-index: 1;
        &.headline-block--breadcrumbs-desktop {
            @media screen and (max-width: rem-calc(993)) {
                display: none;
            }
        }
    }
    .headline-block--breadcrumbs-mobile {
        display: none;
        @media screen and (max-width: rem-calc(993)) {
            display: block;
        }
        .breadcrumbs {
            margin-top: 0;
            padding: 15px 0 30px;
        }
    }
    &--container-wrap {
        padding: 90px 0;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: rem-calc(769)) {
            padding: 30px 0 80px;
        }
    } 
    &--col {
        z-index: 2;
        @media screen and (min-width: rem-calc(992)) {
            min-height: 310px;
        }
    }
    &--image {
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        right: 0;
        padding: 0;
        z-index: -2;
        &--sm{
          position: unset;

        }
        &--desktop{
            @media screen and  (max-width:  rem-calc(993)) {
                background:none !important; 
            }
        } 
        //@media screen and (max-width: rem-calc(769)) {
        //    position: relative;
        //}
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--overlay {
        position: absolute;
        background: url('../../svg/healthedu-slash-large.svg') no-repeat;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        background-position: 180px 0;
        min-height: 588px;
        @media screen and (max-width: rem-calc(1400)) {
            min-height: 668px;
        }
        @media screen and (max-width: rem-calc(1200)) {
            min-height: 750px;
        }

        @media screen and (max-width: rem-calc(1400)) {
            background-position: 20px 0;
        }
        @media screen and (max-width: rem-calc(1200)) {
            background-position: -140px 0;
        }
        &--sm{
            position: absolute;
            background: url('../../svg/line-work-mobile.png') no-repeat;
            //background: url('../../svg/home-banner-top-cut.svg') no-repeat;
            z-index: 1;
            width: 100%;
            height: 100vw;
            top: 0;
            left: 0;
            right: 0;
            background-size: contain;
            background-position: 0 -21%;
           
        }
            // @media screen and  (max-width: rem-calc(992)) {
            //     background:none; 
            // } 
    }
    &--title {
        color: $black;
        font-size: 48px;
        line-height: 58px;
        font-family: $secondary-bold-font, serif;
        text-transform: unset;
        @media screen and (max-width: rem-calc(767)) {
            font-size: 32px;
            line-height: 42px;
        }
    }
    &--desc {
        color: $black;
        font-size: 18px;
        line-height: 24px;
        font-family: $primary-font, sans-serif;
        padding: 5px 0;
        p {
            padding: 5px 0;
            display: block;
        }
    }
    &--buttons {
        margin-top: 10px;
        .btn {
            margin-right: 10px;
        }
    }
    &--spacer {
        width: 100%;
        display: block;
        height: 86px;
    }
}

.Mobile-haadline{
    display: none;
}

@media screen and (max-width: rem-calc(993)) {
    .Mobile-haadline{
        display: block;
        margin: 0 auto;
    }
}

.desktop-headline{
    @media screen and  (max-width: rem-calc(992)) {
        display:none; 
    }

}
