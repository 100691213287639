// ======================================
//             Awards Block
// ======================================

.awards {
    background: #EDF1F4;
    padding: 15px 0;
    @media screen and (min-width: rem-calc(768)) {
        padding: 75px 0 60px 0;
    }
    &--col {
        @media screen and (max-width: 991px) {
            padding: 28px 46px;
        }
    }
    &--image {
        img {
            height: 100%;
            max-height: 119px;
        }
    }
    &--title {
        font-size: 24px;
        line-height: 34px;
        color: $black;
        font-family: $primary-bold-font, sans-serif;
        padding: 8px 0 4px 0;
    }
    &--buttons {
        .btn {
            font-size: 24px;
            line-height: 34px;
            display: block;
            text-align: left;
            margin-bottom: 5px;
        }
    }
}
