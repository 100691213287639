// ===============================
//   Buttons Placeholder Style
// ===============================

.buttons-placeholder {
    padding: rem-calc(72) 0 rem-calc(30) 0;
    &--inner-contain {
        padding: rem-calc(39) 0;
        &.first {
            padding: rem-calc(10) 0 rem-calc(20) 0;
        }
    }
    &--title {
        font-family: $secondary-bold-font, serif;
        font-size: rem-calc(38);
        line-height: rem-calc(48);
        color: $black;   
    }
    &--buttons {
        .btn {
            margin-right: rem-calc(10);
        }
    }
    &--buttons,
    &--title-container {
        max-width: rem-calc(938);
        padding: 0 rem-calc(69);
        @media screen and (max-width: rem-calc(767)) {
            padding: 0 rem-calc(12);
        }
    }
}