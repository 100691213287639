// ======================
// Typography Styles
// ======================
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $secondary-bold-font, serif;
  color: $black;
  font-variation-settings: 'wght' 600;
}

h1, .h1 {
    
}
h2, .h2 {
    font-size: 38px;
    line-height: 40px;
}
h3, .h3 {
    font-size: 32px;
    line-height: 36px;
}
h4, .h4 {
    font-size: 24px;
    line-height: 26px;
}
h5, .h5 {
    font-size: 18px;
    line-height: 21px;
}
h6, .h6 {
    
}

p {
    &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
    font-family: $secondary-bold-font, serif !important;
    font-variation-settings: 'wght' 600;
    }

    &.h1 {
        
    }
    &.h2 {
        font-size: 38px !important;
        line-height: 40px !important;
    }
    &.h3 {
        font-size: 32px !important;
        line-height: 36px !important;
    }
    &.h4 {
        font-size: 24px !important;
        line-height: 26px !important;
    }
    &.h5 {
        font-size: 18px !important;
        line-height: 21px !important;
    }
}


// ======================
// Font Sizes 
// From 12 to 54pt by 2s
// ======================
.fs-12 {font-size: 12px !important;}
.fs-13 {font-size: 12px !important;}
.fs-14 {font-size: 14px !important;}
.fs-15 {font-size: 12px !important;}
.fs-16 {font-size: 16px !important;}
.fs-17 {font-size: 12px !important;}
.fs-18 {font-size: 18px !important;}
.fs-20 {font-size: 20px !important;}
.fs-22 {font-size: 22px !important;}
.fs-24 {font-size: 24px !important;}
.fs-26 {font-size: 26px !important;}
.fs-28 {font-size: 28px !important;}
.fs-30 {font-size: 30px !important;}
.fs-32 {font-size: 32px !important;}
.fs-34 {font-size: 34px !important;}
.fs-36 {font-size: 36px !important;}
.fs-38 {font-size: 38px !important;}
.fs-40 {font-size: 40px !important;}
.fs-42 {font-size: 42px !important;}
.fs-44 {font-size: 44px !important;}
.fs-46 {font-size: 46px !important;}
.fs-48 {font-size: 48px !important;}
.fs-50 {font-size: 50px !important;}
.fs-52 {font-size: 52px !important;}
.fs-54 {font-size: 54px !important;}

// ======================
// Styles & Weights
// ======================
.fw-bold { font-weight: bold !important; }
.fw-bolder { font-weight: bolder !important; }
.fw-normal { font-weight: normal !important; }
.fw-lighter { font-weight:lighter !important; }
.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }
.fs-italic { font-style:italic !important; }
.fs-oblique { font-style:oblique !important; }
.fs-normal { font-style:normal !important; }

// ======================
// Font Variations (used with Variable Fonts)
// ======================
.fv-100 { font-variation-settings: 'wght' 100 !important; }
.fv-150 { font-variation-settings: 'wght' 150 !important; }
.fv-200 { font-variation-settings: 'wght' 200 !important; }
.fv-250 { font-variation-settings: 'wght' 250 !important; }
.fv-300 { font-variation-settings: 'wght' 300 !important; }
.fv-350 { font-variation-settings: 'wght' 350 !important; }
.fv-400 { font-variation-settings: 'wght' 400 !important; }
.fv-450 { font-variation-settings: 'wght' 450 !important; }
.fv-500 { font-variation-settings: 'wght' 500 !important; }
.fv-550 { font-variation-settings: 'wght' 550 !important; }
.fv-600 { font-variation-settings: 'wght' 600 !important; }
.fv-650 { font-variation-settings: 'wght' 650 !important; }
.fv-700 { font-variation-settings: 'wght' 700 !important; }
.fv-750 { font-variation-settings: 'wght' 750 !important; }
.fv-800 { font-variation-settings: 'wght' 800 !important; }
.fv-850 { font-variation-settings: 'wght' 850 !important; }
.fv-900 { font-variation-settings: 'wght' 900 !important; }

// ======================
// Font Transforms
// ======================
.capitalize { text-transform: capitalize !important; }
.uppercase { text-transform: uppercase !important; }
.lowercase { text-transform:lowercase !important; }