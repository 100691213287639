.custom-select {
    border-radius: 0;
}
@media screen and (min-width: rem-calc(1008)) {
  li.dropdown.nav-item {
    .dropdown-toggle {
      border-bottom: solid 4px $white;
    }
    &:hover, &:focus, &:focus-within {
      .dropdown-toggle {
        border-bottom: solid 4px $red;
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}


.dropdown-toggle{
  border: 0 none;
  &:hover {
      i {
        -webkit-transition:none !important;
        transform: translateX(0) !important;
      }
  }

  &.block{
    display: block;
  }
}

.dropdown-custom {
  border: 1px solid $shadow-gray;
  border-radius: 3px;

  &.show{
    border: 1px solid $tertiary_color;

    i{
      -webkit-transform: rotate(180deg) !important;
      transform: rotate(180deg) !important;
    }

    .dropdown-toggle{
      &:hover {
          i {
            -webkit-transform: rotate(180deg) !important;
            transform: translateX(180deg) !important;
          }
        }
    }
  }

  .dropdown-toggle {
    width: 100%;
    text-align: left;
    padding: 8px 16px;
    font-family: 'Arial';
    font-size: 14px;
    font-weight: 400;

    &::after {
      display: none;
    }
  }

  i,.fas {
    position: absolute;
    right: 15px;
    top: 12px;
    z-index: 1;
    color: $tertiary_color;
  }

  .dropdown-menu {
    padding: 0 0 0 0;
    margin: 0 -1px;
    background-color: $white;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-size: 12px;
    top: -5px !important;
    left: 0px;
    border-top: 0 none;
    border-left: 1px solid $tertiary_color;
    border-right: 1px solid $tertiary_color;
    border-bottom: 1px solid $tertiary_color;

    li{
      padding-left: 4px;
      padding-right: 4px;
      margin-bottom: 0;
      a{
        display: block;
        padding: 8px 12px;
        text-decoration: none !important;
        color: $black;
        font-family: 'Arial';
        font-size: 14px;
        font-weight: 400;

        &:hover{
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.13);
        }
      }
    }
  }
  .btn-cancel {
    margin: 7px 0 0 0 !important;
    padding: 0 !important;
    text-decoration: none !important;
    font-size: 16px !important;
    font-family: "Gotham Bold" !important;
    color: $tertiary_color !important;
    border: 0 !important;
    background: transparent !important;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px !important;
    }
  }
  .btn-apply {
    padding: 5px 13px;
    font-size: 16px;
  }

  &.dropdown {
    .dropdown-menu {
      min-width: 300px;
      width: calc(100% + 2px);
    }
  }

  &.fix-dd{
    .dropdown-menu {
      width: calc(100% + 1px);
    }
  }

  .btn {
    display: inline-block !important;
    border: 1px solid #fff;
    background-color: #fff;
    color: #272727;
  }

  .checkbox-menu {
    li {
      padding-left: 4px;
      padding-right: 4px;
      label {
        display: block;
        padding: 10px 12px;
        clear: both;
        line-height: 1.42857143;
        color: $black;
        white-space: nowrap;
        margin: 0;
        font-size: 14px;
        font-family: 'Arial';
        font-weight: 400;
        cursor: pointer;

        &:hover{
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.13);
        }
      }
    }
    input {
      position: relative;
      top:1px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  &.alt{
    @media screen and (min-width: 400px) {
      width: 300px;
    }
    .dropdown-menu {
      top: 5px !important;
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px #0000004D;
      border: 1px solid $shadow-gray;
      border-radius: 3px;
    }
  }
}