// ======================================
//         CTA Interogative Block
// ======================================
.cta-interogative {
    background: #EDF1F4;
    padding: 50px 12px;
    &--container {
        background: $white;
        padding: 44px 47px;
        max-width: 1071px;
        color: $black;
        border-radius: 3px;
        border: 1px solid #D8D8D8;
        @media screen and (max-width: rem-calc(767)) {
            padding: 44px 12px;
        }
    }
    &--title {
        color: $black;
        font-size: 38px;
        line-height: 48px;
        font-family: $secondary-bold-font, serif;
    }
    &--contact {
        
    }
    &--buttons {
        
    }
    &--image {
        text-align: center;
        @media screen and (max-width: rem-calc(767)) {
            text-align: left;
            margin-bottom: 30px;
            img {
                max-height: 120px;
            }
        }
        
    }
    &.cta-cover-img {
        @media screen and (min-width: rem-calc(768)) {
            background-size: cover;
            background-position: center;
            padding-top: 350px;
            padding-bottom: 80px;
        }
        @media screen and (max-width: rem-calc(767)) {
            background-image: none !important;
            .cta-interogative--container {
                border: none;
                padding: 0;
            }
        }
        .cta-interogative--container {
            background-color: #EDF1F4;
        }
    }
    .cta-interogative-content {
        font-size: 18px;
        font-family: $primary-font;
        a {
            font-family: $primary-bold-font;
        }
    }

    .btn {
        padding: 21px 38px;
    }
}
.cta-interogative-img-mobile {
    display: none;
    @media screen and (max-width: rem-calc(767)) {
        display: block;
    }
}