// =============
// descriptive colors
// =============

$white:                     #ffffff;
$gray:                      #E4E4E4;
$grayer:                    #E5E5E5;
$light-gray:                #F5F5F5;
$dark-gray:                 #676767;
$darker-gray:               #3B3B3B;
$shadow-gray:               #BFBFBF;
$hover-bg-gray:             #f8f9fa;
$red:                       #CC0000;
$black:                     #0A0203;
$white:                     #ffffff;
$lgblue:                    #EDF1F4;

// =============
// Color Palette
// =============

$primary_color:             $red;
$secondary_color:           #FFCE00;
$tertiary_color:            #042D61;

// ======================
// Text Color Classes
// ======================
.text-white { color: $white !important; }
.text-black { color: $black !important; }

// ======================
// BG Color Classes
// ======================
.bg-primary { background-color: $primary_color !important; }
.bg-secondary { background-color: $secondary_color !important; }
.bg-tertiary { background-color: $tertiary_color !important; }
.bg-black { background-color: $black !important; }
.bg-transparent { background-color: transparent !important; }
.blue-bg { background: #042D61 !important; }
.gray-bg, .grey-bg { background: #E4E4E4 !important; }
.bg-light-blue { background: #EDF1F4 !important; }
.bg-off-white { background: #F5F5F5 !important;}
