// ======================================
//         Case Studies Component
// ======================================

.case-studies {
    background: #EDF1F4;
    padding: 40px 0;
    &--container {
        max-width: 988px;
    }
    &--row {
        @media screen and (max-width: rem-calc(768)) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    &--title {
        color: $black;
        font-size: 38px;
        line-height: 48px;
        font-family: $secondary-bold-font, serif;
        text-align: center;
    }
    &--desc {
        color: $black;
        font-size: 18px;
        line-height: 24px;
        font-family: $primary-font, sans-serif;
        text-align: center;
    }
    &--cards {
        max-width: 1142px;
        .case-studies--row {
            display: grid;
            gap: 13px;
            @media screen and (min-width: rem-calc(992)) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    &--card {
        background: $white;
        border: 1px solid #9D95A2;
        border-radius: 10px;
        padding: 37px 52px;
        text-decoration: none;
        @media screen and (max-width: rem-calc(767)) {
            padding: 35px 30px;
        }
        .pre,
        .title,
        .desc,
        .name,
        .date {
            font-family: $primary-font, sans-serif;
            color: $black;
            font-size: 16px;
            line-height: 24px;
        }
        .title {
            font-family: $secondary-bold-font, serif;
            font-size: 18px;
            text-decoration: underline;
            padding: 9px 0 26px 0;
        }
        .desc {
            font-size: 18px;
            padding-bottom: 36px;
            p {
                padding: 0;
            }
        }
        .date {
            padding-bottom: 28px;
        }
        .btn {
            &:hover {
                text-decoration: none;
            }
            &::after {
                margin-right: 2px;
            }
        }
        &:hover {
            box-shadow: 0 2px 6px rgb(0 0 0 / 40%);
            text-decoration: none;
            .title {
                color: #1473E6;
            }
            .btn {
                text-decoration: underline;
                &::after {
                    margin-right: 10px;
                }
            }
        }
    }
}
