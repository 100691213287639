// ======================================
//           Twin Column Block
// ======================================
.twin-columns-block {
    @media screen and (min-width: rem-calc(768)) {
        padding: rem-calc(80) 0;
        background: $white;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(245,245,245,1) 50%, rgba(245,245,245,1) 100%);
    }
    @media screen and (max-width: rem-calc(992)) {
        .container {
            max-width: 100%;
        }
    }
    &--title {
        font-family: $secondary-bold-font, serif;
        font-size: rem-calc(38);
        line-height: rem-calc(48);
        color: $black;
    }
    &--desc,
    &--list {
        font-family: $primary-font, sans-serif;
        font-size: rem-calc(18);
        line-height: rem-calc(24);
    }
    &--list {
        margin-bottom: rem-calc(30);
        list-style: none;
        li {
            padding: rem-calc(8) 0;
            &:before {    
                font-family: $fontawesome;
                content: '\f00c';
                margin: 0 rem-calc(9) 0 rem-calc(-10);
                color: $white;
                display: inline-block;
                width: 21px;
                height: 21px;
                font-size: 15px;
                background-color: $red;
                border-radius: 50%;
                padding: 0px 0px 0px 4px;
            }
        }
    }
    &--desc {
        margin: rem-calc(20) 0;
    }
    &--image {
        img {
            width: 100%;
            max-width: rem-calc(461);
            @media screen and (max-width: rem-calc(768)) {
                margin-bottom: rem-calc(20);
            }
        }
    }
    &--buttons {
        .btn {
            font-size: rem-calc(18);
            line-height: rem-calc(18);
            padding: rem-calc(15) rem-calc(49);
        }
    }
    &--left-col,
    &--right-col {
        @media screen and (max-width: rem-calc(768)) {
            padding: rem-calc(51) rem-calc(16);
        }
    }
    &--left-col {
        @media screen and (max-width: rem-calc(768)) {
            background: $white;
        }
        @media screen and (min-width: rem-calc(768)) {
            padding-right: rem-calc(30);
        }
    }
    &--right-col {
        @media screen and (max-width: rem-calc(768)) {
            background: #F5F5F5;
        }
        @media screen and (min-width: rem-calc(768)) {
            padding-left: rem-calc(30);
        }
    }
}
