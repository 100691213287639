// ======================================
//        Two Column Blocks w/ Alt
// ======================================
.two-columns-block,
.two-columns-alt-block {
    background: #F5F5F5;
    padding: rem-calc(51) rem-calc(16);
    @media screen and (min-width: rem-calc(768)) {
        padding: rem-calc(80) 0;
    }
    &--row {
        flex-direction: row;
        @media screen and (min-width: rem-calc(768)) {
            flex-direction: row-reverse;
        }
    }
    @media screen and (max-width: rem-calc(768)) {
        padding: rem-calc(51) 0;
    }
    &--title {
        font-family: $secondary-bold-font, serif;
        font-size: rem-calc(38);
        line-height: rem-calc(48);
        color: $black;
    }
    &--desc,
    &--list {
        font-family: $primary-font, sans-serif;
        font-size: rem-calc(18);
        line-height: rem-calc(24);
    }
    &--list {
        margin-bottom: rem-calc(30);
        list-style: none;
        li {
            padding: 3px 0;
            &:before {    
                font-family: $fontawesome;
                content: '\f00c';
                margin: 0 rem-calc(9) 0 rem-calc(-10);
                color: $white;
                display: inline-block;
                width: 21px;
                height: 21px;
                font-size: 15px;
                background-color: $red;
                border-radius: 50%;
                padding: 0px 0px 0px 4px;
            }
        }
    }
    &--desc {
        margin: rem-calc(20) 0;
    }
    &--image {
        img {
            width: 100%;
            // max-width: rem-calc(461);
            @media screen and (max-width: rem-calc(768)) {
                margin-bottom: rem-calc(20);
                max-width: 100%;
            }
        }
    }
    &.two-columns-dark-bg {
        background-color: #000000;
        .two-columns-block--title, .two-columns-block--desc {
            color: $white;
        }
        ul.two-columns-block--list {
            li {
                color: $white;
                &::before {
                    color: #0A0203;
                    background-color: $white;
                }
            }
        }
    }
}

//This particular attribute only affects the standard one
.two-columns-block {
    &--text-col {
        @media screen and (min-width: rem-calc(768)) {
            padding-right: rem-calc(100);
        }
    }
}

//After inheriting the previous main blocks designs,
//make changes with the following CSS
.two-columns-alt-block {
    background: $black;
    color: $white;
    &--row {
        flex-direction: row-reverse;
        @media screen and (min-width: rem-calc(768)) {
            flex-direction: row;
        }
    }
    &--title {
        color: $white;
    }
    &--list {
        li {
            &:before {
                color: #0A0203;
                background-color: $white;
            }
        }
    }
}