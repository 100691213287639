// ======================================
//           Card Styles
// ======================================

.cards-wrapper {
  background: #EDF1F4;
  .container {
    display: flex;
    gap: 4px;
    max-width: 1130px;
    @media screen and (max-width: 991px) {
      display: block;
      gap: 0;
    }
    .card-column {
      padding: 30px 50px;
      background: $white;
      border: solid 1px #D8D8D8;
      border-radius: 3px;
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      @media screen and (max-width: 991px) {
        margin-bottom: 20px;
        margin-top: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .card-content {
        h1, h2, h3, h4, h5, h6 {
          font-size: 18px;
          line-height: 26px;
          a {
            color: #0A0203;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
        p {
          font-size: 18px;
          margin-bottom: 0;
          line-height: 24px;
        }
      }
    }
    &.offset-cards {
      .card-column {
        margin-top: -140px;
        z-index: 2;
        @media screen and (max-width: 991px) {
          padding: 30px 45px;
          margin-bottom: 20px;
          margin-top: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}