// ======================================
//          Contact Page Styles
// ======================================
.contact-page {
    background: #EDF1F4;
    @media screen and (min-width: rem-calc(992)) {
        background: rgb(237,241,244);
        background: -moz-linear-gradient(90deg, rgba(237,241,244,1) 0%, rgba(237,241,244,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(237,241,244,1) 0%, rgba(237,241,244,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        background: linear-gradient(90deg, rgba(237,241,244,1) 0%, rgba(237,241,244,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#edf1f4",endColorstr="#ffffff",GradientType=1);
    }
    &--container {
        .container {
            @media screen and (max-width: rem-calc(991)) {
                max-width: 100% !important;
                padding: 0 !important;
            }
        }
    }
    &--row {
        overflow: hidden;
    }
    &--contact-col,
    &--form-col {
        padding: 45px 0;
    }
    &--contact-col {
        @media screen and (max-width: rem-calc(991)) {
            padding: 65px 16px 28px 16px;
        }
    }
    &--contact-inner {
        width: 100%;
        @media screen and (min-width: rem-calc(992)) {
            max-width: 465px;
            margin: 0 auto;
        }
        @media screen and (max-width: rem-calc(1199)) {
            padding: 0 30px 0 0;
        }
        @media screen and (max-width: rem-calc(991)) {
            padding: 0;
        }
    }
    &--form-col {
        @media screen and (max-width: rem-calc(991)) {
            background: $white;
        }
        .form {
            padding: 0;
            @media screen and (min-width: rem-calc(1200)) {
                max-width: 383px;
                margin: 0 auto;
                
            }
            @media screen and (max-width: rem-calc(1199)) {
                padding: 0 0 0 30px;
            }
            @media screen and (max-width: rem-calc(991)) {
                padding: 0 15px;
            }
        }
    }
    &--title {
        font-family: $secondary-bold-font, serif;
        font-size: 38px;
        line-height: 48px;
        text-transform: unset;
        @media screen and (min-width: rem-calc(992)) {
            font-size: 48px;
            line-height: 58px;
        }
    }
    &--subtitle {
        font-family: $secondary-bold-font, serif;
        font-size: 24px;
        line-height: 30px;
    }
    &--desc {
        margin-bottom: 25px;
        font-family: $primary-font, sans-serif;
        font-size: 18px;
        line-height: 24px;
    }
    &--details {
        background: $white;
        border-radius: 10px;
        border: 1px solid #9D95A2;
        padding: 36px;
        text-align: center;
        font-size: 18px;
        line-height: 28px;
        .contact-page--details-mobile-toggle {
            display: none;
            line-height: 50px;
            padding: 0 20px;
            font-size: 18px;
            text-transform: uppercase;
            font-family: $primary-bold-font;
            position: relative;
            &::after {
                display: inline-block;
                margin-left: 8px;
                vertical-align: inherit;
                font-family: "Font Awesome 6 Free";
                content: "\f078";
                line-height: 0;
                position: absolute;
                right: 20px;
                top: 23px;
                color: $red;
            }
        }
        @media screen and (max-width: rem-calc(992)) {
            padding: 0;
            text-align: left;
            .contact-page--details-mobile-toggle {
                display: block;
            }
            .contact-page--details-body {
                display: none;
            }
            &.contact-info-dropdown.active {
                .contact-page--details-mobile-toggle {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
                .contact-page--details-body {
                    display: block;
                    padding: 15px 20px 0;
                    border-top: solid 1px #D8D8D8;
                }
            }
        }
        @include box-shadow(0px, 3px, 6px, rgba(0,0,0,0.15));
        a {
            color: #1473E6;
            text-decoration: none;
            &:hover {
                color: #1473E6;
                text-decoration: underline;
            }
        }
        p {
            margin-bottom: 40px;
        }
    }
    &--subheader {
        font-family: $primary-bold-font, sans-serif;
        font-size: 18px;
        line-height: 30px;
        text-transform: uppercase;
    }
}
