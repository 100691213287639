// ======================================
//        Global Styles
// ======================================

body {
    background: $white;
    font-family: $primary-font;
}

html {
    margin-top: 121px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &.sticky-body {
        margin-top: 80px;
    }
    @media screen and (max-width: rem-calc(1007)) {
        margin-top: 75px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: #1269D3;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        color: #1269D3;
    }
}

.mw-800 {
    max-width: 800px;
}

.light-gray-bg {
    background-color: $light-gray !important;
}

.row > * {
    @media screen and (max-width: rem-calc(768)) {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}