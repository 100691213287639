// ======================================
//         Form Placeholder
// ======================================

.form-placeholder {
    background: #EDF1F4;
    padding: 40px 0;
    &--container {
        // max-width: 456px;
    }
    &--desc {
        max-width: 823px;
        background: #F5F5F5;
        padding: 14px 45px;
        margin: 0 auto;
    }
    &--form {
        max-width: 600px;
        padding: 10px;
        margin: 0 auto;
    }
}
