/**
* Fluid Type (from Breon)
* Usage: 
*    h1 {
*        @include fluid-type(20rem, 64rem, 4rem, 8rem);
*    }
*/ 

@mixin fluid-type($min-vw, $max-vw, $min-value, $max-value) {
    $factor: 1 / ($max-vw - $min-vw) * ($max-value - $min-value);
    $calc-value: unquote('#{ $min-value - ($min-vw * $factor) } + #{ 100vw * $factor }');
    min-height: 0vw;
    font-size: $min-value;
    font-size: clamp(#{if($min-value > $max-value, $max-value, $min-value)}, #{$calc-value}, #{if($min-value > $max-value, $min-value, $max-value)});
}