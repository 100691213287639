// ====================================
//             Site Footer
// ====================================
.site-footer {
  background: $white;
  &--head-title {
    font-family: $secondary-bold-font, serif;
    font-size: 18px;
    line-height: 28px;
    color: $black;
    padding: 10px 0;
    border-bottom: 1px solid $black;
    margin-bottom: 10px;
  }
  &--head-links {
    list-style: none;
    margin: 0;
    padding: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    font-family: $tertiary-font, serif;
    font-variation-settings: 'wght' 350;
    font-size: rem-calc(16);
    line-height: rem-calc(21);
    margin-bottom: 20px;
    @media screen and (max-width: 769px) {
      border-bottom: solid 1px #0A0203;
      margin-bottom: 0;
      padding: 30px 0;
      columns: 1;
      -webkit-columns: 1;
    }
    li {
      padding: rem-calc(8) 0;
      @media screen and (max-width: 769px) {
        padding: 15px 0;
      }
    }
    a {
      text-decoration: none;
      color: $black;
      &:hover {
        text-decoration: underline;
        color: $black;
      }
    }
  }
  &--buttons {
    display: block;
    margin-top: 29px;
    .btn {
      padding: 12px 22px;
      border-width: 1px;
      margin-right: 15px;
      @media screen and (max-width: 769px) {
        margin: 20px 0;
      }
      &:hover {
        border-width: 1px;
      }
    }
  }
  &--head {
    @media screen and (min-width: 768px) {
      padding: 25px 0;
    }
    @media screen and (max-width: 991px) {
      padding: 25px 0 0;
    }
    @media screen and (max-width: 769px) {
      .container {
        max-width: 100% !important;
      }
    }
    .site-footer--logo {
      max-width: 321px;
    }
  }
  &--tail {
    background: $white;
    @media screen and (min-width: 768px) {
      background: #edf1f4;
    }
    @media screen and (max-width: 769px) {
      .container {
        max-width: 100% !important;
        padding-top: 0;
      }
    }
  }
  &--tail-container {
    display: flex;
    color: $black;
    font-family: $tertiary-font, serif;
    font-variation-settings: 'wght' 350;
    @media screen and (max-width: 1199px) {
      flex-direction: column-reverse !important;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
      min-height: 35px;
      align-items: center;
    }
    a {
      color: $black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p {
      margin: 0;
    }
  }
  &--tail-content {
    background: #edf1f4;
    @media screen and (min-width: 768px) {
      background: none;
    }
    @media screen and (max-width: 769px) {
      margin: 0 -12px;
      padding: 12px;
      text-align: center;
      a {
        display: block;
      }
    }
  }
  &--tail-links {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    @media screen and (max-width: 1199px) {
      margin-right: auto;
      margin-left: auto;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
      margin-left: auto;
    }
    @media screen and (max-width: 769px) {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 0;
      padding: 0;
    }
    li {
      padding: 0 rem-calc(24);
      @media screen and (max-width: 769px) {
        margin-bottom: 24px;
      }
    }
  }
}
