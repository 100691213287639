
.video-embed-html5 {
    video {
        object-fit: contain;
        width: 100% !important;
        height: auto !important;
    }
}

.video-embed-youtube { 
    .video-inner {
        position: relative; 
        padding-bottom: 56.25%; 
        height: 0; 
        overflow: hidden; 
        iframe, object, embed { 
            position: absolute; 
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%; 
        }
    }
}