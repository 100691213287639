// ==================================
//       Header & Site Menu
// ==================================
.site-header {
  font-family: $primary-font, sans-serif;
  .header-container {
    position: unset;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: flex-end;
    .nav {
      display: flex;
      @media screen and (max-width: rem-calc(1007)) {
        display: none;
      }
    }
    &--buttons {
      display: inline-flex;
      margin-right: 10px;
      @media screen and (max-width: rem-calc(1007)) {
        display: none;
      }
      .btn {
        margin-right: 10px;
        padding: 12px 22px;
        border-width: 1px;
        margin-bottom: 0;
        margin-top: 0;
        &:hover {
          border-width: 1px;
        }
      }
    }
  }
  .main-menu {
    flex-direction: column;
  }
  .navbar-brand {
    margin: 0;
    padding: 0;
    margin-right: auto;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    img {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    @media screen and (min-width: rem-calc(1008)) {
      padding: 24px 10px 24px 30px;
    }
    .logo {
      @media screen and (max-width: rem-calc(1007)) {
        width: auto;
        height: rem-calc(46);
        margin: rem-calc(16);
      }
    }
  }
  .header-main {
    background: $white;
    border-bottom: 6px solid #ED1C24;
    z-index: 999;
    width: 100%;
    left: 0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    position: fixed !important;
    top: 0;
    @media screen and (min-width: 768px) {
      position: relative;
    }
    &.fixed-top {
      position: fixed !important;
      top: 0;
      border-bottom: 3px solid #ED1C24;
      .navbar-brand {
        @media screen and (min-width: rem-calc(768)) {
          padding: 5px 10px 5px 30px;
          img {
            scale: 90%;
          }
        }
        @media screen and (max-width: rem-calc(1007)) {
          padding: 0;
          img {
            scale: 100%;
          }
        }
      }
    }
    &.open {
      top: 0;
      position: fixed;
    }
    .nav-item {
      align-items: center;
      display: flex;
      text-align: center;
      &:last-child {
        .title-nav-link {
          margin-right: 58px;
          @media screen and (max-width: rem-calc(1189)) {
            margin-right: 16px;
          }
        }
      }
    }
    .nav-link {
      &.title-nav-link {
        font-family: $fourth-font;
        color: $black;
        font-weight: bold;
        font-variation-settings: "wght" 700;
        font-size: 18px;
        line-height: 18px;
        text-decoration: none;
        @include fluid-type(20rem, 64rem, 1rem, 1.125);
        padding: 8px 0 4px;
        margin: 0 28px;
        @media screen and (max-width: rem-calc(1189)) {
          margin: 0 8px;
        }
        @media screen and (min-width: rem-calc(1007)) {
          border-bottom: solid 4px $white;
          &:hover, &:focus {
            border-bottom: solid 4px $red;
          }
        }
      }
    }
    .dropdown-menu {
      border: 1px solid #9D95A2;
      border-radius: 10px;
      box-shadow: 1px 2px 3px rgb(0 0 0 / 40%);
      font-size: 18px;
      line-height: 18px;
      padding: 6px;
      top: 100%;
      left: 0;
      @media screen and (max-width: 991px) {
        position: relative;
        top: unset;
        left: unset;
        margin-top: 0;
      }
    }
    .dropdown-item {
      text-decoration: none;
      border-radius: 5px;
      padding: 8px 22px;
      &:hover {
        background: #D8D8D8;
        text-decoration: underline;
      }
    }
  }
  .navbar-toggler {
    border: none;
    margin-right: 10px;
    order: 3;
    font-size: 28px;
    border-radius: 5px;
    background: $red;
    color: $white;
    padding: 9px 10px;
    min-width: 50px;
    @media screen and (max-width: rem-calc(1007)) {
      display: initial !important;
    }
    &:focus {
      text-decoration: none;
      outline: 0;
      box-shadow: none;
    }
    .xmark {
      display: none;
    }
    .hamburger {
      display: block;
    }
    &.open {
      .xmark {
        display: block;
      }
      .hamburger {
        display: none;
      }
    }
  }
  .drop-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: $white;
    margin: 0;
    opacity: 0;
    /* transform: translateY(-60px); */
    padding: rem-calc(100) 0 rem-calc(20) 0;
    overflow-y: scroll;
    flex-direction: column;
    /* transition: all 0.3s linear; */
    visibility: hidden;
    &.open {
      opacity: 1;
      /* transform: translateY(0); */
      visibility: visible;
      @media screen and (max-width: rem-calc(1007)) {
        display: flex !important;
      }
    }
    @media screen and (min-width: rem-calc(1007)) {
      display: none !important;
    }
    @media screen and (max-width: rem-calc(576)) {
      padding: rem-calc(100) 0 40px 0;
    }
    &--buttons {
      margin-top: auto;
      padding: 0 16px;
    }
    .btn {
      margin-bottom: 18px;
      display: block;
    }
    .dropdown-menu {
      position: relative !important;
      transform: none !important;
      background: none;
      border: 0;
      .title {
        color: #00355f;
        font-family: $secondary-font;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        border-bottom: 1px solid #00355f;
        padding: 0 0 10px 0;
        margin-bottom: 10px;
        font-variation-settings: "wght" 700;
      }
      .menuitem {
        display: block;
        color: #00355f;
        text-decoration: none;
        font-size: 18px;
        line-height: 20px;
        font-family: $primary-font;
        font-variation-settings: "wght" 400;
        padding: 10px 0;
        &:hover {
          text-decoration: underline;
        }
      }
      &.show {
        background: #F5F5F5;
        border: 1px solid #9D95A2;
        border-radius: 0;
        a {
          color: #1269D3;
          font-size: 18px;
          line-height: 42px;
        }
      }
    }
    .dropdown-toggle {
      &::after {
        font-family: $fontawesome;
        display: inline-block;
        content: "\f0d7";
        border: none;
        font-size: 18px;
        float: right;
      }
      &.show {
        &::after {
          content: "\f0d8";
        }
      }
    }
    .expand-navbar-mobile {
      list-style: none;
      margin-top: rem-calc(15);
      padding: 0 rem-calc(2);
      .nav {
        flex-direction: column;
      }
      .title-nav-link {
        color: $black;
        text-decoration: none;
        font-size: rem-calc(34);
        line-height: rem-calc(44);
        font-family: $fourth-font;
        text-transform: unset;
        font-variation-settings: "wght" 700;
        &:hover {
          text-decoration: underline;
        }
        &.show {
          background: #D8D8D8;

        }
      }
    }
  }
}

body.navbar-open {
  @media screen and (max-width: rem-calc(1007)) {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
}
