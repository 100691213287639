// ====================================
//         Cover Page Styles
// ====================================
.cover-page {
    display: flex;
    position: relative;
    background: #F5F5F5;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    &--logo {
        position: absolute;
        top: 10px;
        left: auto;
        max-width: 285px;
        @media screen and (min-width: rem-calc(768)) {
            top: 24px;
            left: 64px;
        }
    }
    &--col {
        text-align: center;
    }
    &--title {
        font-size: 50px;
        line-height: 55px;
        font-family: $fourth-font, sans-serif;
        color: $black;
        text-transform: unset;
        font-weight: normal;
        font-variation-settings: 'wght' 300;
        margin-bottom: 44px;
        @media screen and (min-width: rem-calc(768)) {
            font-size: 90px;
            line-height: 105px;
        }
    }
    &--buttons {
        .btn {
            margin-bottom: 20px;
            @media screen and (min-width: 500px) {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}
