// ===============================
//     Headline Home Styles
// ===============================

.headliner-home {
    background: #EDF1F4 url('../../images/stethoscope-large.png') no-repeat;
    background-size: contain;
    background-position: right top;
    @media screen and (max-width: rem-calc(769)) {
        background-position: unset;
        background-size: contain;
    }
    &--container {
        padding: rem-calc(56) rem-calc(0);
        @media screen and (max-width: rem-calc(992)) {
            padding: 0 0 60px;
        }
    }
    @media screen and (max-width: rem-calc(991)) {
        background: #EDF1F4;
    }

    &--title {
        font-size: 55px;
        line-height: 65px;
        font-family: $secondary-bold-font, serif;
        color: $black;
        text-transform: unset;
        @media screen and (max-width: rem-calc(767)) {
            font-size: 28px;
            line-height: 34px;
        }
    }
    &--desc {
        
    }
    &--buttons {

    }
    .two-columns-block--list {
        padding: 0 0 0 10px;
    }
}

.headliner-home--desc {
    font-family: $secondary-bold-font, serif;
    font-size: 24px;
    margin-bottom: 0;
}

.headliner-home-mobile-img {
    height: 477px;
    background-size: contain !important;
    background-position: 100% 0 !important;
    margin-right: -12px;
    margin-left: -12px;
    display: none;
    @media screen and (max-width: rem-calc(768)) {
        height: 384px;
    }
    @media screen and (max-width: rem-calc(576)) {
        height: 288px;
    }
    @media screen and (max-width: rem-calc(480)) {
        height: 240px;
    }
    @media screen and (max-width: rem-calc(390)) {
        height: 195px;
    }
    @media screen and (max-width: rem-calc(992)) {
        display: block;
    }
}