// ======================================
//           Carousel Block
// ======================================
.carousel-wrap {
  background: #EDF1F4;
  padding: 60px 0 80px 0;
  overflow: hidden;
  &--title {
    font-size: 38px;
    line-height: 48px;
    color: $black;
    font-family: $secondary-bold-font, serif;
    text-align: center;
    margin-bottom: 29px;
  }
}

.carousel {
  &--item {
    display: block;
  }
  &--item-inner {
    background: $white;
    border: 1px solid #9D95A2;
    border-radius: 10px;
    padding: 67px 28px;
    margin: 10px 17px;
    @include box-shadow(0px, 3px, 6px, rgba(0,0,0,0.15));
  }
  &--desc {
    font-family: $primary-font, sans-serif;
    color: $black;
    font-size: 18px;
    line-height: 30px;
  }
  &--hr {
    width: 100%;
    max-width: 242px;
    background: $black;
    height: 2px;
    border: 0;
    opacity: 1;
    margin: 35px 0;
  }
  &--author-details {
    display: flex;
  }
  &--author-img {
    width: 77px;
    height: 77px;
    border-radius: 100%;
    border: 1px solid #9D95A2;
  }
  &--author {
    margin-left: 10px;
  }
  &--name,
  &--position {
    font-size: 18px;
    line-height: 30px;
  }
  &--name {
    font-family: $primary-bold-font, sans-serif;
  }
  &--position {
    font-family: $primary-font, sans-serif;
    font-style: italic;
  }
  .slick-arrow {
    background: $white;
    border: 1px solid $black;
    border-radius: 100%;
    width: 57px;
    height: 57px;
    z-index: 10;
    &.slick-next,
    &.slick-prev {
      &::after {
        font-family: $fontawesome;
        position: absolute;
        top: 27px;
        z-index: 21;
        color: $black;
        left: 18px;
        font-size: 20px;
      }
    }
    &.slick-next {
      &::after {
        content: "\f061";
      }
    }
    &.slick-prev {
      &::after {
        content: "\f060";
      }
    }
    &:hover {
      background: #D8D8D8;
    }
  }
  .slick-prev-icon, 
  .slick-next-icon {
    display: none;
  }
  .slick-dots {
    bottom: -50px;
    li {
      button {
        height: 14px;
        width: 14px;
        margin-top: -4px;
        margin-left: -4px;
        border: 1px solid #6D6E71;
        background:#6D6E71;
        border-radius: 100%;
      }
      .slick-dot-icon {
        display: none;
      }
      &.slick-active {
        button {
          border: 1px solid $black;
          background:$white;
        }
      }
    }
  }
  .slick-autoplay-toggle-button {
    display: none;
  }
  @media screen and (max-width: 768px) {
    &.slick-slider.slick-dotted {
      padding-bottom: 70px;
      .slick-next, .slick-prev {
        top: 96%;
      }
      .slick-next {
        right: 70px;
      }
      .slick-prev {
        left: 70px;
      }
    }
  }
}
