// ====================================
//               Buttons
// ====================================
.btn {
  font-size: rem-calc(18);
  line-height: rem-calc(18);
  padding: rem-calc(15) rem-calc(49);
  border-radius: 4px;
  font-family: $primary-bold-font;
  font-weight: 900;
  transition: none !important;
  display: block;
  width: 100%;
  text-decoration: none;
  margin-bottom: 9px;
  margin-top: 9px;
  @media screen and (min-width: rem-calc(500)) {
    display: inline-block;
    width: auto;
  }
  &:hover {
    text-decoration: none;
  }
  &.btn-primary {
    color: white;
    border: 2px solid #3264a6;
    background: #3264a6;
    &.hover,
    &:hover {
      background: white;
      border: 2px solid #3264a6;
      color: #042d61;
    }
  }
  &.btn-secondary {
    color: #042d61;
    border: 2px solid #ffce00;
    background: #ffce00;
    &.hover,
    &:hover {
      color: $white;
      border: 2px solid #ffce00;
      background: #042d61;
    }
  }
  &.btn-success {

  }
  &.btn-danger {
    background-color: $red;
    border: solid 2px $red;
    color: $white;
    &:hover {
      background-color: $black;
      color: $white;
      border: solid 2px $black;
    }
  }
  &.btn-warning {

  }
  &.btn-info {

  }
  &.btn-light {
    background-color: $white;
    border: solid 2px $white;
    color: $black;
    &:hover {
      background-color: $red;
      color: $white;
      border: solid 2px $red;
    }
  }
  &.btn-dark {
    background-color: $black;
    border: solid 2px $black;
    color: $white;
    &:hover {
      background-color: $white;
      color: $black;
      border: solid 2px $black;
    }
  }
  &.btn-link {
    color: #1269D3;
    font-family: $primary-bold-font, sans-serif;
    font-size: rem-calc(18);
    line-height: rem-calc(34);
    text-decoration: none;
    padding: 0;
    display: inline-block;
    &::after {
      font-family: $fontawesome;
      content: "\f054";
      display: inline-block;
      margin-left: 2px;
    }
    &:hover {
      color: #1269D3;
      text-decoration: underline;
      &::after {
        margin-left: 10px;
      }
    }
  }
  &.btn-primary,
  &.btn-secondary,
  &.btn-success,
  &.btn-danger,
  &.btn-warning,
  &.btn-info,
  &.btn-light,
  &.btn-dark {
    &.disabled {
      color: $white;
      border: 2px solid #676767;
      background: #676767;
      &.hover,
      &:hover {
        color: $white;
        border: 2px solid #676767;
        background: #676767;
      }
    }
  }

  // =====================
  //    Outlines
  // =====================

  &.btn-outline-primary {
    color: #042d61;
    border: 2px solid #042d61;
    background: transparent;
    &.hover,
    &:hover {
      background: #042d61;
      border: 2px solid #042d61;
      color: $white;
    }
  }

  &.btn-outline-secondary {
  }

  &.btn-outline-success {
  }

  &.btn-outline-danger {
    background-color: $white;
    border: solid 2px $red;
    color: $black;
    &:hover {
      background-color: $black;
      color: $white;
      border: solid 2px $black;
    }
  }
  &.btn-outline-warning {

  }
  &.btn-outline-info {

  }
  &.btn-outline-light {
    background-color: transparent;
    border: solid 2px $white;
    color: $white;
    &:hover {
      background-color: $red;
      color: $white;
      border: solid 2px $red;
    }    
  }
  &.btn-outline-dark {

  }

  &.btn-outline-primary,
  &.btn-outline-secondary,
  &.btn-outline-success,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-info,
  &.btn-outline-light,
  &.btn-outline-dark {
    &.disabled {
      color: #676767;
      border: 2px solid #676767;
      background: transparent;
      &.hover,
      &:hover {
        color: #676767;
        border: 2px solid #676767;
        background: transparent;
      }
    }
  }
  &.btn-lg {
    font-size: 24px;
    padding: 18px 55px;
    @media screen and (max-width: 900px) {
      padding: 22px 18px;
    }
  }
}

.btn-href {
  text-decoration: none;
  &:hover {
      text-decoration: none;
  }
}

.inline-btns {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  gap: 15px;
  @media screen and (max-width: rem-calc(768)) {
    display: block;
  }
}