
@mixin fontface-include($name, $filename, $style:'normal', $weight: '400') {
  @font-face {
    font-family:"#{$name}";
    src:url("/assets/fonts/#{$filename}.woff2") format("woff2"),
      url("/assets/fonts/#{$filename}.woff") format("woff"),
      url("/assets/fonts/#{$filename}.otf") format("opentype");
    font-style:#{$style};
    font-weight:#{$weight};
  }
}