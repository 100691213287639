// ======================================
//            Form Styles
// ======================================
.form {
  border-radius: 10px;
  font-size: 16px;
  line-height: 26px;
  font-family: $primary-font, sans-serif;
  color: $black;
  opacity: 1;
  .form-group {
    padding: 10px 0;
  }
  span.required {
    color: $red;
  }
  input, textarea, select,
  input::placeholder, textarea::placeholder, select::placeholder {
    font-size: 16px;
    line-height: 26px;
    font-family: $primary-font, sans-serif;
    color: $black;
    opacity: 1;
    border-radius: 0;
  }
  .check-form-group {
    display: grid;
    font-size: 18px;
    line-height: 21px;
    .form-group {
      padding: 0;
    }
    input[type="checkbox"],
    input[type="radio"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: inline-grid;
      place-content: center;
      &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        background-color: CanvasText;
      }
      &:checked {
        background: #3264A6;
        border-color: #3264A6;
      }
      &:checked::before {
        transform: scale(1);
        background-color: $white;
      }
      &:focus {
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
      }
      &:disabled {
        --form-control-color: var(--form-control-disabled);
        color: var(--form-control-disabled);
        cursor: not-allowed;
      }
    }
    input[type="radio"] {
      border-radius: 100%;
    }
    label {
      padding-left: 20px;
    }
    .custom-control {
      padding: 8px 0;
    }
    .custom-control-label {
      display: inline-grid;
      width: calc(100% - 60px);
    }
  }
  .form-control {
    padding: 0.5rem 1rem;
    border: 1px solid #6D6E71;
    background-color: #F5F5F5;
    position: relative;
  }
  .form-select {
    position: relative;
    padding: 0.7rem 1rem;
    border: 1px solid #6D6E71;
    background-color: #F5F5F5;
    border-radius: 3px;
  }
  label {
    padding-bottom: 0.4rem;
  }
}
