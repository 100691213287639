// ======================================
//             Logos Block
// ======================================
:root {
    --logo-gap: 2rem;
    --logo-duration: 30s;
    --logo-scroll-start: 0;
    --logo-scroll-end: calc(-100% - var(--logo-gap));
}

.logos {
    position: relative;
    display: flex;
    min-height: 417px;
    overflow: hidden;
    @media screen and (min-width: rem-calc(768)) {
        align-items: center;
        min-height: 253px;
    }
    &--container-fluid,
    &--container,
    &--row,
    &--col {
        @media screen and (max-width: rem-calc(768)) {
            padding: 0;
        }
    }
    &--container {
        position: relative;
        z-index: 2;
    }
    &--title {
        font-size: 28px;
        line-height: 38px;
        font-family: $secondary-bold-font, serif;
        color: $black;
        text-align: center;
        padding: 52px 16px;
        @media screen and (max-width: rem-calc(768)) {
            padding: 52px 30px;
        }
        @media screen and (min-width: rem-calc(768)) {
            font-size: 38px;
            line-height: 48px;
            text-align: left;
            padding: 0;
            max-width: 455px;
        }
    }
    &--overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 42%, rgba(255,255,255,0) 63%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 42%, rgba(255,255,255,0) 63%, rgba(255,255,255,0) 100%);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 42%, rgba(255,255,255,0) 63%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        @media screen and (max-width: rem-calc(767)) {
            display: none;
        }
    }
    &--marquee {
        display: flex;
        gap: var(--logo-gap);
        position: absolute;
        z-index: 0;
        top: 188px;
        @media screen and (min-width: rem-calc(768)) {
            top: calc(50% - 85px);
        }
    }
    &--marquee-group {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: var(--logo-gap);
        min-width: 100%;
        animation: scroll-left var(--logo-duration) linear infinite;
        img {
            max-height: 171px;
        }
    }

    @keyframes scroll-left {
        from {
            transform: translateX(var(--logo-scroll-start));
        }
        to {
            transform: translateX(var(--logo-scroll-end));
        }
    }

    @media (prefers-reduced-motion: reduce) {
        .marquee-group {
          animation-play-state: paused;
        }
    }
}
