// =============
// Custom Font selections - Use these to select Primary, secondary fonts
// =============
$fontawesome: "Font Awesome 6 Free";
$primary-font: 'Lato';
$primary-bold-font: 'Lato Bold';
$secondary-font: 'Merriweather';
$secondary-bold-font: 'Merriweather Bold';
$tertiary-font: "Open Sans";
$tertiary-bold-font: "Open Sans";
$fourth-font: "Roboto Regular";

// ======================
// Fonts
// ======================
@include fontface-include($primary-font, "lato/Lato-Regular", 'normal', '700');
@include fontface-include($primary-bold-font, "lato/Lato-Bold", 'normal', '900');
@include fontface-include($secondary-font, "merriweather/Merriweather-Regular", 'normal', '700');
@include fontface-include($secondary-bold-font, "merriweather/Merriweather-Black", 'normal', '900');
@include fontface-include($tertiary-font, "OpenSans/OpenSans", 'normal', '700');
@include fontface-include($fourth-font, "Roboto/Roboto-Regular", 'normal', '700');

// ======================
// Icon Libraries
// ======================

$fa-font-path: "../../fonts";

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';