// ======================================
//        Columns Placeholder
// ======================================

.columns-placeholder {
    &--title {
        font-size: rem-calc(38);
        line-height: rem-calc(48);
        color: $black;
        font-family: $secondary-bold-font, serif;
    }
    &--row {
        padding-top: rem-calc(15);
    }
    &--container {
        padding: rem-calc(36) rem-calc(69);
        @media screen and (max-width: rem-calc(767)) {
            padding: rem-calc(36) rem-calc(12);
        }
        &.max-sm {
            max-width: rem-calc(938);
        }
        &.max-lg {
            max-width: rem-calc(938);
            .columns-placeholder--row {
                @media screen and (min-width: rem-calc(1100)) {
                    margin-left: rem-calc(-120);
                    margin-right: rem-calc(-180);
                }
            }
        }
    }
    p {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        font-family: $primary-font, sans-serif;
    }
}
