// ======================================
//           Service Block
// ======================================
.service-block {
    padding: rem-calc(102) 0;
    background: #EDF1F4;
    &--title {
        font-size: rem-calc(38);
        line-height: rem-calc(48);
        color: $black;
        text-align: center;
        font-family: $secondary-bold-font, serif;
        max-width: rem-calc(800);
        margin: 0 auto;
        padding-bottom: rem-calc(40);
    }
    &--container {
        max-width: 1125px;
        .card {
            background: none;
            border: 0;
            border-radius: 0;
        }
        .card-title {
            font-size: rem-calc(24);
            line-height: rem-calc(30);
            font-family: $secondary-bold-font, serif;
            a {
                color: #0A0203;
                text-decoration: underline;
                &:hover {
                    color: #1473E6;
                }
            }
        }
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }
        .card-text {
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            font-family: $primary-font, sans-serif;
            color: $black;
        }
    }
    &--row {
        display: grid;
        gap: 63px;
        @media screen and (min-width: rem-calc(768)) {
            grid-template-columns: repeat(3, 1fr);
            gap: 10px; //For tablet sizes
        }
        @media screen and (min-width: rem-calc(900)) {
            gap: 32px; //For med desktop sizes
        }
        @media screen and (min-width: rem-calc(1024)) {
            gap: 63px;
        }
    }
    &--hr {
        width: 100%;
        height: rem-calc(2);
        background: $red;
        display: block;
        border: 0;
        opacity: 1;
        padding: 0;
        margin: rem-calc(12) 0;
        max-width: rem-calc(242);
    }
}