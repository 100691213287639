// ======================================
//           Accordion Styles
// ======================================

.accordion-wrap {
  background: #EDF1F4;
  padding: 40px 0;
  &--title {
    font-size: 38px;
    line-height: 48px;
    font-family: $secondary-bold-font, serif;
    color: $black;
    text-align: center;
    padding: 14px 0;
  }
  &--desc {
    font-size: 18px;
    line-height: 24px;
    font-family: $primary-font, sans-serif;
    color: $black;
    text-align: center;
    max-width: 735px;
    padding: 14px 0 55px 0;
    margin: 0 auto;
  }
}

.accordion {
  background: none;
  padding-bottom: 48px;
  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
  &--title {
    font-size: 24px;
    line-height: 24px;
    font-family: $secondary-font, serif;
    color: $black;
    margin: 0 0 10px 0;
    padding: 0;
  }
  .accordion-button {
    font-size: 18px;
    line-height: 28px;
    font-family: $secondary-bold-font, serif;
    color: $black;
    background: $white;
    &:hover {
      text-decoration: underline;
      &::before {
        text-decoration: underline;
        text-decoration-color: $white;
      }
    }
    &::after {
      content: none;
    }
    &::before {
      font-family: $fontawesome;
      content: "\f078";
      color: $red;
      font-weight: bold;
      text-decoration:none;
      display:inline-block;
      margin-right: 12px;
      width: 16px;
    }
    &.collapsed {
      background: $white;
      &::before {
        content: "\f054";
      }
    }
    &:not(.collapsed) {
      box-shadow: none;
    }
  }
  .accordion-item {
    margin-bottom: 11px;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    &:first-child {
      border-top: 1px solid #D8D8D8;
    }
    &:last-child {
      border-bottom: 1px solid #D8D8D8;
    }
  }
  .accordion-body {
    font-size: 16px;
    line-height: 26px;
    font-family: $primary-font, sans-serif;
    padding: 33px 34px;
    margin: 0 15px;
    border-top: solid 1px #D8D8D8;
  }
}

.row {
  .col-sm-12:first-of-type {
    .accordion--title {
      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
    }
  }
  .col-sm-12 {
    .accordion--title {
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }
}