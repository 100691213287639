// ===============================
//     Breadcrumb Styles
// ===============================

.breadcrumbs {
    color: $black;
    font-size: rem-calc(14);
    line-height: rem-calc(24);
    font-family: $primary-font, sans-serif;
    margin-top: 8px;
    &--list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        column-gap: 3px;
    }
    &--item {
        color: #1269D3;
        &::after {
            content: "/";
        }
        a {
            color: #1269D3;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &.home {
            &::before {
                font-family: $fontawesome;
                content: "\f053";
                font-size: 12px;
            }
        }
        &.last {
            color: $black;
            &::after {
                content: "";
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}
